import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './EventBooking.module.scss';
import AppStepper from '../../components/AppStepper/AppStepper';
import { useAppStepper } from '../../components/AppStepper/useAppStepper';
import BookingSummary from '../../components/BookingSummary';
import {
  createReservation,
  fetchSummary,
  getProductTimeSlots
} from '../../store/booking/booking.actions';
import { Trans, useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import { ReactComponent as CloseIcon } from '../../images/cancel-current-color.svg';
import { ReactComponent as ArrowIcon } from '../../images/arrow-current-color.svg';
import classNames from 'classnames';
import { FormProvider, useForm } from 'react-hook-form';
import EventSummary from './components/EventSummary/EventSummary';
import { useConfirmationDialog } from '../../components/ConfirmationDialog/useConfirmationDialog';
import { customerSelector } from '../../store/customer/customer.selectors';
import useWithSelection from '../../hooks/useWithSelection';
import { getEventProducts } from '../../store/eventProducts/eventProducts.actions';
import {
  EEventProductType,
  EventProduct,
  EventProducts
} from '../../store/eventProducts/eventProducts.types';
import ThankYouPage from './components/ThankYouPage/ThankYouPage';
import {
  Booking,
  ETImeSlotProductType,
  SummaryType,
  TimeSlot
} from '../../store/booking/booking.types';
import { toastUtil } from '../../utils/toast.utils';
import { CUSTOM_DURATION_ID } from '../../components/BookingSteps/DurationStep/DurationStep';
import useWithDispatch from '../../hooks/useWithDispatch';
import { getDraftById } from '../../store/dashboard/dashboard.actions';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { getEventTypeCodes } from '../../store/code/code.actions';
import { eventTypeCodesSelector } from '../../store/code/code.selectors';
import { STEPS, STEP_COMPONENTS, TEventLoading } from './constants/steps';
import { ECustomerType, generateEventForm } from './untils/generate-event-form';
import { generateEventData } from './untils/generate-event-data';
import Loader from '../../components/Loader';
import StyledTooltip from './components/StyledTooltip/StyledTooltip';
import { ReactComponent as InfoIcon } from '../../images/Info-current-color.svg';
import {
  getLounge,
  getSupervisorByLoungeId
} from '../../store/lounge/lounge.actions';
import { Supervisor } from '../../store/lounge/lounge.types';
import 'react-photo-view/dist/react-photo-view.css';
import queryString from 'qs';
import { scrollToElement } from '../../utils/scroll-to-element.utils';

export interface IEventBookForm {
  id: string;
  participantsAmount: number;
  loungeId: string;
  durationId: string;
  duration: number; // hours
  date: Date | null;
  time: string;
  timeSlotId: string;
  foodId: string;
  programId: string;
  selectedExtras: string[];
  firstLine: string;
  secondLine: string;
  carBranding: File | string | null;
  trackBranding: File | string | null;
  organiserFirstName: string;
  organiserLastName: string;
  organiserEmail: string;
  organizerPhoneNumber: string;
  companyName: string;
  companyAddress1: string;
  companyAddress2: string;
  companyCity: string;
  companyRegion: string;
  companyCountryId: string;
  companyTax: string;
  companyZipCode: string;
  processignPersonalData: boolean;
  getUpdates: boolean;
  processingBillingData: boolean;
  isSpecialRequest: boolean;
  specialRequest: string;
  simulatorsAmount: number;
  termsRead: boolean;
  privacyRead: boolean;
  coupon: string;
  additionalRequest: string;
  customerType: ECustomerType;
}

const SAVE_AS_DRAFT_CONFIRMATION = {
  title: 'booking.savingEventAsADraft',
  text: (
    <Trans i18nKey="booking.savingEventAsADraftInfo">
      You are saving all event details <b>besides the time slot</b>.<br />
      Timeslot will be blocked for you only after confirming the event.
    </Trans>
  )
};

const UNAUTHORIZED_SAVE_CONFIRMATION = {
  title: 'booking.createAnAccount',
  text: 'booking.createAnAccountInfo',
  okText: 'booking.createAnAccount',
  okIcon: <ArrowIcon />
};

const DEFAULT_EVENT_OPTIONS = {
  [EEventProductType.eventCatering]: [],
  [EEventProductType.event]: [],
  [EEventProductType.eventStructure]: [],
  [EEventProductType.eventTrophies]: [],
  [EEventProductType.eventCarBranding]: [],
  [EEventProductType.eventTrackBranding]: []
};

function round(value: number | string, decimals: number = 2): string {
  const multiplier = Math.pow(10, decimals);
  return (
    Math.round(Number.parseFloat(value.toString()) * multiplier) / multiplier
  ).toFixed(2);
}

function checkOptionIncludeSelectedId(
  options: EventProduct[],
  selectedId: string
): boolean {
  return options && options.some(({ id }) => selectedId === id);
}

const EventBooking: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams<{ id: string }>();
  const [t, i18n] = useTranslation();
  const form = useForm<IEventBookForm>({
    mode: 'onChange',
    shouldUnregister: false,
    defaultValues: generateEventForm()
  });
  const { openDialog, dialog } = useConfirmationDialog();
  const {
    currentStep,
    latestVisitedStep,
    isLast,
    nextStep,
    prevStep,
    setCurrentStep
  } = useAppStepper(STEPS, 0);
  const customer = useWithSelection(customerSelector);
  const getDraftByIdDispatch = useWithDispatch(getDraftById);
  const getEventTypesDispatch = useWithDispatch(getEventTypeCodes);
  const loungeDispatch = useWithDispatch(getLounge);
  const eventTypes = useWithSelection(eventTypeCodesSelector);
  const prevWatchValues = useRef<Partial<IEventBookForm> | null>(null);
  const prevLanguage = useRef<string>(i18n.language);
  const abortController = useRef<AbortController>(new AbortController());
  const [isLoading, setIsLoading] = useState<TEventLoading>({
    darftEvent: false,
    saving: false,
    summary: false,
    products: false,
    timeSlots: false
  });
  const [invalidSteps, setInvalidSteps] = useState<string[]>([]);
  const [supervisor, setSupervisor] = useState<Supervisor | null>(null);
  const [timeSlots, setTimeSlots] = useState<TimeSlot[]>([]);
  const [isSummary, setIsSummary] = useState<boolean>(false);
  const [isCreated, setIsCreated] = useState<boolean>(false);
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(false);
  const [priceSummary, setPriceSummary] = useState<SummaryType | null>(null);
  const [eventOptions, setEventOptions] = useState<EventProducts>(
    DEFAULT_EVENT_OPTIONS
  );

  const [isMobileMode, setIsMobileMode] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent
      );
    const elements = document.getElementsByTagName('meta');
    const viewport = Array.from(elements).find(
      (meta) => meta.name === 'viewport'
    );
    let content = '';

    if (viewport && !isMobile) {
      content = viewport.content;
      viewport.content = 'min-width=1024px';
    }

    setIsMobileMode(isMobile);

    return () => {
      if (viewport && content) {
        viewport.content = content;
      }
    };
  }, []);

  const watchFields = form.watch([
    'loungeId',
    'duration',
    'durationId',
    'timeSlotId',
    'time',
    'participantsAmount',
    'foodId',
    'selectedExtras',
    'coupon'
  ]);

  const isSpecialRequest = form.watch('isSpecialRequest');
  const programId = form.watch('programId');

  useEffect(() => {
    loungeDispatch(true);

    const params = queryString.parse(location.search.replace('?', ''));

    if (
      params.lang &&
      typeof params.lang === 'string' &&
      i18n.languages.includes(params.lang)
    ) {
      i18n.changeLanguage(params.lang);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!customer || !id) return;

    setIsLoading((loading) => ({ ...loading, darftEvent: true }));

    getDraftByIdDispatch(id)
      .then((draft: Booking | null) => {
        if (!draft) {
          toastUtil('error', t('booking.noEventDraftFound'));
          return;
        }

        const event = generateEventForm(draft);
        let latestVisited = 0;

        if (event.foodId) {
          latestVisited = 4;
          if (event.programId) latestVisited = 5;
          if (event.selectedExtras.length) latestVisited = 6;
          if (
            event.organiserEmail ||
            event.organizerPhoneNumber ||
            event.organiserFirstName ||
            event.organiserLastName ||
            event.companyAddress1 ||
            event.companyAddress2 ||
            event.companyName ||
            event.companyZipCode ||
            event.companyRegion ||
            event.companyCountryId ||
            event.companyCity ||
            event.companyTax
          )
            latestVisited = 7;
        } else {
          if (event.loungeId) latestVisited = 1;
          if (event.durationId) latestVisited = 2;
          if (event.time) latestVisited = 3;
        }

        form.reset(event);
        setCurrentStep(latestVisited);
      })
      .catch((err: any) => {
        if (err.message === 'canceled') return;

        const msg = err && err.meta ? err.meta.message : err.message;
        toastUtil('error', msg);
      })
      .finally(() => {
        setIsLoading((loading) => ({ ...loading, darftEvent: false }));
        setIsFirstLoad(true);
      });
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (!isFirstLoad) return;

    if (Object.values(isLoading).every((loading) => !loading)) {
      setIsFirstLoad(false);
      // HACK: To call a the function when view is updated
      setTimeout(validateSteps, 1000);
    }
    // eslint-disable-next-line
  }, [isFirstLoad, isLoading]);

  useEffect(() => {
    if (
      !watchFields.timeSlotId ||
      (watchFields.timeSlotId && watchFields.durationId) ||
      !timeSlots.length ||
      !eventOptions.event.length
    )
      return;

    const timeSlot = timeSlots.find(({ id }) => id === watchFields.timeSlotId);
    const duration = timeSlot
      ? eventOptions.event.find(({ label }) => label === timeSlot.name)
      : null;
    form.setValue('durationId', duration ? duration.id : '');
    // eslint-disable-next-line
  }, [
    watchFields.timeSlotId,
    watchFields.durationId,
    timeSlots,
    eventOptions.event
  ]);

  useEffect(() => {
    const controller = new AbortController();

    setIsLoading((loading) => ({ ...loading, products: true }));

    getEventProducts(
      watchFields.participantsAmount,
      watchFields.loungeId,
      controller
    )
      .then((options) => {
        setEventOptions(options);

        if (!options) return setEventOptions(DEFAULT_EVENT_OPTIONS);

        const values = form.getValues();

        if (
          watchFields.durationId &&
          !checkOptionIncludeSelectedId(options.event, watchFields.durationId)
        ) {
          values.durationId = '';
          values.timeSlotId = '';
          values.duration = 0;
          values.time = '';
        } else if (
          watchFields.durationId &&
          watchFields.durationId !== CUSTOM_DURATION_ID
        ) {
          const selectedDuration = options.event.find(
            ({ id }) => watchFields.durationId === id
          );

          values.duration = selectedDuration
            ? selectedDuration.event_duration_multiplier
            : values.duration;
        }

        if (
          watchFields.foodId &&
          !checkOptionIncludeSelectedId(
            options.event_catering,
            watchFields.foodId
          )
        ) {
          values.foodId = '';
        }

        if (
          programId &&
          !checkOptionIncludeSelectedId(options.event_structure, programId)
        ) {
          values.programId = '';
        }

        if (
          watchFields.selectedExtras[0] &&
          !checkOptionIncludeSelectedId(
            options.event_trophies,
            watchFields.selectedExtras[0]
          )
        ) {
          values.selectedExtras[0] = '';
          values.firstLine = '';
          values.secondLine = '';
        }

        if (
          watchFields.selectedExtras[1] &&
          !checkOptionIncludeSelectedId(
            options.event_car_branding,
            watchFields.selectedExtras[1]
          )
        ) {
          values.selectedExtras[1] = '';
          values.carBranding = null;
        }

        if (
          watchFields.selectedExtras[2] &&
          !checkOptionIncludeSelectedId(
            options.event_track_branding,
            watchFields.selectedExtras[2]
          )
        ) {
          values.selectedExtras[2] = '';
          values.trackBranding = null;
        }

        form.reset(values);
        setTimeout(
          () =>
            validateSteps().then((firstInvalid) => {
              if (
                !firstInvalid ||
                (firstInvalid && firstInvalid.firstInvalidIndex !== 0)
              )
                return;

              setCurrentStep(firstInvalid.firstInvalidIndex);
              scrollToElement({ elementId: firstInvalid.firstInvalidKey });
            }),
          1
        );
      })
      .catch((err) => {
        if (err.message === 'canceled') return;

        const msg = err && err.meta ? err.meta.message : err.message;
        toastUtil('error', msg);
        setEventOptions(DEFAULT_EVENT_OPTIONS);
      })
      .finally(() =>
        setIsLoading((loading) => ({ ...loading, products: false }))
      );
    getEventTypesDispatch();

    return () => controller.abort();
    // eslint-disable-next-line
  }, [watchFields.participantsAmount, watchFields.loungeId, t]);

  useEffect(() => {
    const controller = new AbortController();

    setIsLoading((loading) => ({ ...loading, timeSlots: true }));

    getProductTimeSlots(controller)
      .then((timeSlots) =>
        setTimeSlots(
          timeSlots.filter(
            ({ product_type }) => product_type === ETImeSlotProductType.event
          )
        )
      )
      .catch((err) => {
        if (err.message === 'canceled') return;

        const msg = err && err.meta ? err.meta.message : err.message;
        toastUtil('error', msg);
      })
      .finally(() =>
        setIsLoading((loading) => ({ ...loading, timeSlots: false }))
      );

    return () => controller.abort();
  }, [t]);

  useEffect(() => {
    if (
      prevWatchValues.current &&
      JSON.stringify(prevWatchValues.current) === JSON.stringify(watchFields) &&
      prevLanguage.current === i18n.language
    )
      return;

    abortController.current.abort();
    prevWatchValues.current = watchFields;
    prevLanguage.current = i18n.language;

    const { loungeId, duration, timeSlotId, time } = watchFields;

    if (!loungeId || !timeSlotId || !duration || !time) return;

    setTimeout(
      () => setIsLoading((loading) => ({ ...loading, summary: true })),
      1
    );

    const controller = new AbortController();
    abortController.current = controller;

    (async () =>
      fetchSummary(
        await generateEventData({
          values: form.getValues(),
          eventTypes,
          summary: true
        }),
        controller
      )
        .then(({ data }) => setPriceSummary(data))
        .catch((err) => {
          if (err.message === 'canceled') return;

          setPriceSummary(null);
          const msg = err && err.meta ? err.meta.message : err.message;
          toastUtil('error', msg);
        })
        .finally(() =>
          setTimeout(() =>
            setIsLoading((loading) => ({ ...loading, summary: false }))
          )
        ))();
    // eslint-disable-next-line
  }, [watchFields, eventTypes, t]);

  useEffect(() => {
    if (!watchFields.loungeId) return;

    const controller = new AbortController();

    getSupervisorByLoungeId(watchFields.loungeId, controller)
      .then(setSupervisor)
      .catch((err) => {
        if (err.message === 'canceled') return;

        const msg = err && err.meta ? err.meta.message : err.message;
        toastUtil('error', msg);
      });

    return () => controller.abort();
  }, [watchFields.loungeId]);

  const onExit = () =>
    openDialog({
      okText: 'booking.exit',
      okIcon: <CloseIcon width={16} />
    }).then(
      (isConfirmed) =>
        isConfirmed && window.open(process.env.REACT_APP_WEBSITE_URL, '_self')
    );

  const onSaveAsDraft = () =>
    openDialog(
      customer ? SAVE_AS_DRAFT_CONFIRMATION : UNAUTHORIZED_SAVE_CONFIRMATION
    ).then((isConfirmed) => {
      if (!isConfirmed) return;

      if (customer) return onCreate(true);

      window.open(`${window.origin}/auth/sign-in`, '_blank');
    });

  const onMoveToSummary = async () => {
    const isValid = await form.trigger();

    if (isValid) return setIsSummary(true);

    const firstInvalid = await validateSteps();

    if (firstInvalid) {
      setCurrentStep(firstInvalid.firstInvalidIndex);
      scrollToElement({ elementId: firstInvalid.firstInvalidKey });
    }
  };

  const validateSteps = async (
    latestVisited?: number
  ): Promise<
    { firstInvalidIndex: number; firstInvalidKey: string } | undefined
  > => {
    let firstInvalid: number | undefined;
    const steps = await Promise.all(
      STEP_COMPONENTS.slice(
        0,
        (latestVisitedStep || latestVisited || 0) + 1
      ).map(
        ({ key, requiredFields }, index) =>
          requiredFields &&
          Promise.all(requiredFields.map((field) => form.trigger(field))).then(
            (results) => {
              const isStepInvalid = results.some((isValid) => !isValid);

              if (isStepInvalid && firstInvalid === undefined)
                firstInvalid = index;

              return isStepInvalid ? key : '';
            }
          )
      )
    );
    const invalidSteps = steps.filter(Boolean) as string[];

    setInvalidSteps(invalidSteps);

    return invalidSteps.length && firstInvalid !== undefined
      ? { firstInvalidIndex: firstInvalid, firstInvalidKey: invalidSteps[0] }
      : undefined;
  };

  const onCreate = async (draft: boolean = false) => {
    setIsLoading((loading) => ({ ...loading, saving: true }));

    try {
      await createReservation(
        await generateEventData({ values: form.getValues(), eventTypes, draft })
      );

      if (draft) {
        toastUtil('success', t('booking.draftEventHasBeenSaved'));
        history.push('/');
      } else {
        toastUtil('success', t('booking.eventHasBeenCreated'));
        setIsCreated(true);
      }
    } catch (err) {
      const error = err as any;
      const msg = error && error.meta ? error.meta.message : error.message;
      toastUtil('error', msg);
    }

    setIsLoading((loading) => ({ ...loading, saving: false }));
  };

  const summaryData = useMemo(() => {
    if (!priceSummary)
      return (
        <div className={styles.summaryEmptyState}>
          {t('booking.summaryEmptyState')}
        </div>
      );

    const discounts = priceSummary
      ? priceSummary.products.flatMap(({ discounts }) => discounts)
      : [];
    const discount = discounts.length
      ? discounts.reduce((acc, discount) => ({
          ...acc,
          price_effect: (+acc.price_effect + +discount.price_effect).toString()
        }))
      : null;

    return (
      <div className={styles.summaryTable}>
        {priceSummary.products.map(({ product_name, base_price }, index) => {
          let name = product_name;

          if (!name) {
            const duration = eventOptions.event.find(
              ({ id }) => id === watchFields.durationId
            );
            name = !duration
              ? name
              : `${duration.label} ${
                  duration.id === CUSTOM_DURATION_ID
                    ? ` - ${watchFields.duration} ${t(
                        'booking.steps.duration.counterSuffix'
                      )}`
                    : ''
                }`;
          }

          return (
            <React.Fragment key={name}>
              <div className={styles.summaryTable__title}>
                <p>{name}</p>
                {index === 1 && isSpecialRequest ? (
                  <p className={styles.summaryTable__specialRequest}>
                    {'+' +
                      t('booking.steps.summary.specialRequest').toLowerCase()}
                    <StyledTooltip
                      arrow
                      placement="top-start"
                      title={
                        t(
                          'booking.steps.summary.specialRequestTooltip'
                        ) as string
                      }
                    >
                      <InfoIcon />
                    </StyledTooltip>
                  </p>
                ) : (
                  ''
                )}
              </div>
              <div className={styles.summaryTable__price}>
                {base_price ? round(base_price) : ''}
              </div>
            </React.Fragment>
          );
        })}
        {discount ? (
          <>
            <div className={styles.summaryTable__title}>
              <p>{discount.name}</p>
            </div>
            <div className={styles.summaryTable__price}>
              {discount.price_effect ? round(discount.price_effect) : ''}
            </div>
          </>
        ) : (
          ''
        )}
        {isSummary ? (
          <>
            <div className={styles.summaryTable__total}>
              <span>{t('booking.total')}</span>
              <span className={styles.caption}>
                {t('booking.excludedVatInfo', {
                  vat: priceSummary.products[0].vat,
                  currency: priceSummary.currency
                })}
              </span>
            </div>
            <div
              className={classNames(
                styles.summaryTable__total,
                styles.summaryTable__price,
                styles.summaryTable__price_big
              )}
            >
              {priceSummary.final_price_sum
                ? round(priceSummary.final_price_sum)
                : ''}
            </div>
          </>
        ) : (
          ''
        )}
      </div>
    );
    // eslint-disable-next-line
  }, [
    eventTypes,
    priceSummary,
    isSummary,
    isSpecialRequest,
    watchFields.duration,
    t
  ]);

  const isLoaderShown = isLoading.darftEvent || isLoading.saving;
  const isSaveAsDarftAvailable =
    watchFields.participantsAmount &&
    watchFields.loungeId &&
    watchFields.timeSlotId &&
    watchFields.time;

  if (isMobileMode) {
    return (
      <div className={styles.eventBooking}>
        <p className={styles.eventBooking__pageNotAvailable}>
          <Trans i18nKey="booking.steps.mobileIsNotAllowed">
            Oops, this part is still under construction. If you wish to use it,
            please enable <b>"Desktop Mode"</b> on your mobile browser, or use{' '}
            <b>your desktop</b>
          </Trans>
        </p>
      </div>
    );
  }

  return (
    <div
      className={classNames(styles.eventBooking, {
        [styles.eventBooking_scrollable]: isSummary
      })}
    >
      {isSummary || isCreated ? (
        ''
      ) : (
        <div className={styles.header}>
          <div className={classNames('container', styles.header__content)}>
            <AppStepper
              className={styles.header__stepper}
              steps={STEPS}
              errorSteps={invalidSteps}
              currentStep={currentStep}
            />
            <StyledTooltip
              arrow
              title={t('booking.saveAsDraftDisabledTooltip') as string}
              placement="top"
              disableHoverListener={!!isSaveAsDarftAvailable}
            >
              <div>
                <Button
                  outline
                  small
                  className={styles.header__button}
                  disabled={isLoaderShown || !isSaveAsDarftAvailable}
                  label={t('booking.saveEventAsDraft')}
                  onClick={onSaveAsDraft}
                />
              </div>
            </StyledTooltip>
            <Button
              small
              outline
              label={t('booking.exit')}
              icon={<CloseIcon width={16} />}
              onClick={onExit}
            />
          </div>
        </div>
      )}
      <div className={styles.content}>
        <FormProvider {...form}>
          {isCreated ? (
            <div className={styles.content__scrollableContainer}>
              <ThankYouPage />
            </div>
          ) : isSummary ? (
            <div className={styles.content__scrollableContainer}>
              <EventSummary
                supervisor={supervisor}
                isPriceLoading={isLoading.summary}
                summary={summaryData}
                eventOptions={eventOptions}
                toggleSummary={() => setIsSummary(false)}
                onSubmit={onCreate}
              />
            </div>
          ) : (
            <>
              <div className={styles.content__cards}>
                <h1 className={styles.content__title}>
                  {t('booking.eventBookingTitle')}
                </h1>
                {STEP_COMPONENTS.slice(0, latestVisitedStep + 1).map(
                  (
                    {
                      key,
                      component: Component,
                      eventOption,
                      requiredFields,
                      loadingFields
                    },
                    index
                  ) => {
                    const isStepInvalid = invalidSteps.includes(key);
                    return (
                      <div id={key} key={key}>
                        <Component
                          isLoading={
                            loadingFields
                              ? loadingFields.some((field) => isLoading[field])
                              : false
                          }
                          invalid={isStepInvalid}
                          collapsed={currentStep !== index}
                          options={eventOption ? eventOptions[eventOption] : []}
                          trophiesOption={
                            eventOptions.event_trophies
                              ? eventOptions.event_trophies[0]
                              : null
                          }
                          carBrandingOption={
                            eventOptions.event_car_branding
                              ? eventOptions.event_car_branding[0]
                              : null
                          }
                          trackBrandingOption={
                            eventOptions.event_track_branding
                              ? eventOptions.event_track_branding[0]
                              : null
                          }
                          timeSlots={timeSlots}
                          requiredFields={requiredFields || []}
                          isForwardDisabledBySummary={false && !priceSummary}
                          onBack={prevStep}
                          onForward={() => {
                            if (isLast) return onMoveToSummary();

                            if (isStepInvalid) validateSteps();

                            nextStep();
                          }}
                          onClick={async () => {
                            setCurrentStep(index);
                            validateSteps();
                          }}
                          revalidateStep={
                            isStepInvalid ? () => validateSteps() : undefined
                          }
                        />
                        {latestVisitedStep === index ? (
                          <p
                            className={classNames(
                              styles.caption,
                              styles.caption_pdTop
                            )}
                          >
                            <Trans i18nKey="booking.steps.caption">
                              If our standard options don't fit your needs you
                              may
                              <a href="mailto:events@racing-unleashed.com">
                                contact us
                              </a>
                              and prepare a tailor made event.
                            </Trans>
                          </p>
                        ) : (
                          ''
                        )}
                      </div>
                    );
                  }
                )}
              </div>
              <div className={styles.content__sidebar}>
                <BookingSummary
                  title="booking.priceSummary"
                  currency={priceSummary ? priceSummary.currency : undefined}
                  total={
                    priceSummary
                      ? round(priceSummary.final_price_sum)
                      : undefined
                  }
                  vat={priceSummary ? priceSummary.products[0].vat : undefined}
                  isLoading={isLoading.summary}
                  isExcludedVat
                >
                  {summaryData}
                </BookingSummary>
              </div>
            </>
          )}
        </FormProvider>
      </div>
      {dialog}
      {isLoaderShown ? (
        <Loader fullSize color="black" width={80} height={80} />
      ) : (
        ''
      )}
    </div>
  );
};

export default EventBooking;
